import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
// import { PlugnotasNfseServicoCreate, PlugnotasNfseServicoUpdate } from '@/interfaces/plugnotasNfseServico';
let PlugnotasNfceProduto = class PlugnotasNfceProduto extends VuexModule {
    constructor() {
        super(...arguments);
        this.plugnotasNfseProduto = [];
    }
    setPlugnotasNfceProduto(payload) {
        this.plugnotasNfseProduto = payload;
    }
    async plugnotasNfceProdutoCreate(obj) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            const response = await api.plugnotasNfceProdutoCreate(mainStore.token, obj);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Produto criado com sucesso',
                color: 'success',
            });
            if (response) {
                this.setPlugnotasNfceProduto([...this.plugnotasNfseProduto, response.data]);
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceProdutoUpdate(obj) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            const response = await api.plugnotasNfceProdutoUpdate(mainStore.token, obj);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Produto atualizado com sucesso',
                color: 'success',
            });
            if (response) {
                const productIndex = this.plugnotasNfseProduto.findIndex((product) => product.id === obj.id);
                if (productIndex !== -1) {
                    this.plugnotasNfseProduto[productIndex] = response.data;
                }
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceBuscarFinanceiro() {
        try {
            const response = await api.plugnotasNfceBuscarFinanceiro(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceProdutoDelete(id) {
        const response = await api.plugnotasNfceProdutoDelete(mainStore.token, id);
        try {
            if (response) {
                mainStore.addNotification({
                    content: 'Produto excluído com sucesso',
                    color: 'success'
                });
            }
            this.setPlugnotasNfceProduto(this.plugnotasNfseProduto.filter((x) => x.id !== id));
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceEmitirFinanceiro(financeiroId) {
        const loadingNotification = { content: 'Emitindo', showProgress: true };
        try {
            const response = await api.plugnotasNfceEmitirFinanceiro(mainStore.token, financeiroId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceEmitirFinanceiroParcelaMovimentacao(financeiroParcelaMovimentacaoId) {
        try {
            const response = await api.plugnotasNfceEmitirFinanceiroParcelaMovimentacao(mainStore.token, financeiroParcelaMovimentacaoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceEmitirFinanceiroParcela(financeiroParcelaId) {
        try {
            const response = await api.plugnotasNfceEmitirFinanceiroParcela(mainStore.token, financeiroParcelaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasSolicitarCancelamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            const response = await api.plugnotasSolicitarCancelamento(mainStore.token, payload.tipoNota, payload.plugnotasId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Solicitação de cancelamento enviada',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceProdutosByCompanyId(companyId) {
        try {
            const response = await api.plugnotasNfceProdutosByCompanyId(mainStore.token, companyId);
            if (response) {
                this.setPlugnotasNfceProduto(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], PlugnotasNfceProduto.prototype, "setPlugnotasNfceProduto", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceProdutoCreate", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceProdutoUpdate", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceBuscarFinanceiro", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceProdutoDelete", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceEmitirFinanceiro", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceEmitirFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceEmitirFinanceiroParcela", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasSolicitarCancelamento", null);
__decorate([
    Action
], PlugnotasNfceProduto.prototype, "plugnotasNfceProdutosByCompanyId", null);
PlugnotasNfceProduto = __decorate([
    Module({ name: 'plugnotasNfceProduto' })
], PlugnotasNfceProduto);
export default PlugnotasNfceProduto;
