import { getModule } from 'vuex-module-decorators';
import ActionModule from '@/store/modules/action';
import AsaasModule from '@/store/modules/asaas';
import AsaasConfiguracaoModule from '@/store/modules/asaas_configuracao';
import AlunoModule from '@/store/modules/aluno';
import AtHomeModule from '@/store/modules/atHome';
import AnalyticsModule from '@/store/modules/analytics';
import AtividadeModule from '@/store/modules/atividade';
import BancoModule from '@/store/modules/banco';
import BryModule from '@/store/modules/bry';
import DrivechatModule from '@/store/modules/drivechat';
import CaixaModule from '@/store/modules/caixa';
import CampanhaModule from '@/store/modules/campanhas';
import CargoModule from '@/store/modules/cargo';
import CdaModule from '@/store/modules/cda';
import CityModule from '@/store/modules/city';
import CompanyConfiguracaoCursoDescontoModule from '@/store/modules/companyConfiguracaoCursoDesconto';
import CompanyConfiguracaoDiaVencimentoParcelaModule from '@/store/modules/companyConfiguracaoDiaVencimentoParcela';
import CompanyConfiguracaoIntegracaoBancariaModule from '@/store/modules/companyConfiguracaoIntegracaoBancaria';
import CompanyConfiguracaoMaterialParcelamentoModule from '@/store/modules/companyConfiguracaoMaterialParcelamento';
import CompanyConfiguracaoModule from '@/store/modules/companyConfiguracao';
import CompanyConfiguracaoNegociacaoModule from '@/store/modules/companyConfiguracaoNegociacao';
import CompanyConfiguracaoNegociacaoMultaRescisoriaContratoModule from '@/store/modules/companyConfiguracaoNegociacaoMultaRescisoriaContrato';
import CompanyModule from '@/store/modules/company';
import CompeticaoEtapaModule from '@/store/modules/competicaoEtapa';
import CompeticaoModule from '@/store/modules/competicao';
import ContaBancariaFormaPagamentoModule from '@/store/modules/contaBancariaFormaPagamento';
import ContaBancariaModule from '@/store/modules/contaBancaria';
import ContratoModule from '@/store/modules/contrato';
import CursoModule from '@/store/modules/curso';
import DashboardModule from '@/store/modules/dashboard';
import DepartamentoModule from '@/store/modules/departamento';
import DiagnosticoModule from '@/store/modules/diagnostico';
import DocumentoFiscalModule from '@/store/modules/documentoFiscal';
import EnderecoModule from '@/store/modules/endereco';
import EntidadeModule from '@/store/modules/entidade';
import EstoqueModule from '@/store/modules/estoque';
import FeriadoModule from '@/store/modules/feriado';
import FiltroPorTabelaModule from '@/store/modules/filtroPorTabela';
import FinanceiroCategoriaJurosDescontoModule from '@/store/modules/financeiroCategoriaJurosDesconto';
import FinanceiroCategoriaModule from '@/store/modules/financeiroCategoria';
import FinanceiroModule from '@/store/modules/financeiro';
import FinanceiroParcelaModule from '@/store/modules/financeiroParcela';
import FinanceiroParcelaMovimentacaoModule from '@/store/modules/financeiroParcelaMovimentacao';
import FinanceiroParcelaRenegociacaoModule from '@/store/modules/financeiroParcelaRenegociacao';
import FornecedorModule from '@/store/modules/fornecedor';
import JornadaColaboradorModule from '@/store/modules/jornadaColaborador';
import LivroModule from '@/store/modules/livro';
import LogModule from '@/store/modules/log';
import MainModule from '@/store/modules/main';
import MapaHorarioModule from '@/store/modules/mapaHorario';
import ObjecaoModule from '@/store/modules/objecao';
import OcorrenciaModule from '@/store/modules/ocorrencia';
import OcorrenciaTarefaModule from '@/store/modules/ocorrenciaTarefa';
import ParceriaAcaoModule from '@/store/modules/parceriaAcao';
import ParceriaCupomHistoricoModule from '@/store/modules/parceriaCupomHistorico';
import ParceriaCupomModule from '@/store/modules/parceriaCupom';
import ParceriaModule from '@/store/modules/parceria';
import PixModule from '@/store/modules/pix';
import PlugnotasConfiguracaoModule from '@/store/modules/plugnotasConfiguracao';
import PlugnotasModule from '@/store/modules/plugnotas';
import PlugnotasNfceProdutoModule from '@/store/modules/plugnotasNfceProduto';
import PlugnotasNfeModule from '@/store/modules/plugnotasNfe';
import PlugnotasNfseServicoModule from '@/store/modules/plugnotasNfseServico';
import PontoParadaModule from '@/store/modules/pontoParada';
import ProspexModule from '@/store/modules/prospex';
import ProspexDemonstrativaModule from '@/store/modules/prospexDemonstrativa';
import QuadroCdaModule from '@/store/modules/quadroCda';
import RelatorioModule from '@/store/modules/relatorio';
import ReposicaoModule from '@/store/modules/reposicao';
import RotaModule from '@/store/modules/rota';
import RotaPontoParadaAlunoModule from '@/store/modules/rotaPontoParadaAluno';
import RotaPontoParadaModule from '@/store/modules/rotaPontoParada';
import SalaModule from '@/store/modules/sala';
import SefazModule from '@/store/modules/sefaz';
import SongModule from '@/store/modules/song';
import SyngooConfiguracaoModule from '@/store/modules/syngooConfiguracao';
import SyngooMensagemConfiguracaoModule from '@/store/modules/syngooMensagemConfiguracao';
import TicketModule from '@/store/modules/ticket';
import ToolModule from '@/store/modules/tool';
import TreinamentoModule from '@/store/modules/treinamento';
import TurmaAlunoAtividadeModule from '@/store/modules/turmaAlunoAtividade';
import TurmaAlunoModule from '@/store/modules/turmaAluno';
import TurmaAtividadeModule from '@/store/modules/turmaAtividade';
import TurmaModule from '@/store/modules/turma';
import UserActionModule from '@/store/modules/userAction';
import UserModule from '@/store/modules/user';
import UserToolModule from '@/store/modules/user_tool';
import UtilsModule from '@/store/modules/utils';
import VeiculoModule from '@/store/modules/veiculo';
import HelpdeskModule from '@/store/modules/helpdesk';
import MarketingDigitalModule from '@/store/modules/marketingDigital';
import NovidadesModule from '@/store/modules/novidades';
import MetaModule from '@/store/modules/meta';
import MetaIndividualModule from '@/store/modules/meta_individual';
let actionStore;
let asaasStore;
let asaasConfiguracaoStore;
let alunoStore;
let atHomeStore;
let analyticsStore;
let atividadeStore;
let bancoStore;
let bryStore;
let drivechatStore;
let caixaStore;
let campanhaStore;
let cargoStore;
let cdaStore;
let cityStore;
let companyConfiguracaoCursoDescontoStore;
let companyConfiguracaoDiaVencimentoParcelaStore;
let companyConfiguracaoIntegracaoBancariaStore;
let companyConfiguracaoMaterialParcelamentoStore;
let companyConfiguracaoNegociacaoMultaRescisoriaContratoStore;
let companyConfiguracaoNegociacaoStore;
let companyConfiguracaoStore;
let companyStore;
let competicaoEtapaStore;
let competicaoStore;
let contaBancariaFormaPagamentoStore;
let contaBancariaStore;
let contratoStore;
let cursoStore;
let dashboardStore;
let departamentoStore;
let diagnosticoStore;
let documentoFiscalStore;
let enderecoStore;
let entidadeStore;
let estoqueStore;
let feriadoStore;
let filtroPorTabelaStore;
let financeiroCategoriaJurosDescontoStore;
let financeiroCategoriaStore;
let financeiroParcelaMovimentacaoStore;
let financeiroParcelaRenegociacaoStore;
let financeiroParcelaStore;
let financeiroStore;
let fornecedorStore;
let jornadaColaboradorStore;
let livroStore;
let logStore;
let mainStore;
let mapaHorarioStore;
let objecaoStore;
let ocorrenciaStore;
let ocorrenciaTarefaStore;
let parceriaAcaoStore;
let parceriaCupomHistoricoStore;
let parceriaCupomStore;
let parceriaStore;
let pixStore;
let plugnotasConfiguracaoStore;
let plugnotasNfceProdutoStore;
let plugnotasNfeStore;
let plugnotasNfseServicoStore;
let plugnotasStore;
let pontoParadaStore;
let prospexStore;
let prospexDemonstrativaStore;
let quadrocdaStore;
let relatorioStore;
let reposicaoStore;
let rotaPontoParadaAlunoStore;
let rotaPontoParadaStore;
let rotaStore;
let salaStore;
let sefazStore;
let songStore;
let syngooConfiguracaoStore;
let syngooMensagemConfiguracaoStore;
let ticketStore;
let toolStore;
let treinamentoStore;
let turmaAlunoAtividadeStore;
let turmaAlunoStore;
let turmaAtividadeStore;
let turmaStore;
let userActionStore;
let userStore;
let userToolStore;
let utilsStore;
let veiculoStore;
let helpdeskStore;
let marketingDigitalStore;
let novidadesStore;
let metaStore;
let metaIndividualStore;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function initializeStores(store) {
    actionStore = getModule(ActionModule, store);
    asaasStore = getModule(AsaasModule, store);
    asaasConfiguracaoStore = getModule(AsaasConfiguracaoModule, store);
    alunoStore = getModule(AlunoModule, store);
    atHomeStore = getModule(AtHomeModule, store);
    analyticsStore = getModule(AnalyticsModule, store);
    atividadeStore = getModule(AtividadeModule, store);
    bancoStore = getModule(BancoModule, store);
    bryStore = getModule(BryModule, store);
    drivechatStore = getModule(DrivechatModule, store);
    caixaStore = getModule(CaixaModule, store);
    campanhaStore = getModule(CampanhaModule, store);
    cargoStore = getModule(CargoModule, store);
    cdaStore = getModule(CdaModule, store);
    cityStore = getModule(CityModule, store);
    companyConfiguracaoCursoDescontoStore = getModule(CompanyConfiguracaoCursoDescontoModule, store);
    companyConfiguracaoDiaVencimentoParcelaStore = getModule(CompanyConfiguracaoDiaVencimentoParcelaModule, store);
    companyConfiguracaoIntegracaoBancariaStore = getModule(CompanyConfiguracaoIntegracaoBancariaModule, store);
    companyConfiguracaoMaterialParcelamentoStore = getModule(CompanyConfiguracaoMaterialParcelamentoModule, store);
    companyConfiguracaoNegociacaoMultaRescisoriaContratoStore = getModule(CompanyConfiguracaoNegociacaoMultaRescisoriaContratoModule, store);
    companyConfiguracaoNegociacaoStore = getModule(CompanyConfiguracaoNegociacaoModule, store);
    companyConfiguracaoStore = getModule(CompanyConfiguracaoModule, store);
    companyStore = getModule(CompanyModule, store);
    competicaoEtapaStore = getModule(CompeticaoEtapaModule, store);
    competicaoStore = getModule(CompeticaoModule, store);
    contaBancariaFormaPagamentoStore = getModule(ContaBancariaFormaPagamentoModule, store);
    contaBancariaStore = getModule(ContaBancariaModule, store);
    contratoStore = getModule(ContratoModule, store);
    cursoStore = getModule(CursoModule, store);
    dashboardStore = getModule(DashboardModule, store);
    departamentoStore = getModule(DepartamentoModule, store);
    diagnosticoStore = getModule(DiagnosticoModule, store);
    documentoFiscalStore = getModule(DocumentoFiscalModule, store);
    enderecoStore = getModule(EnderecoModule, store);
    entidadeStore = getModule(EntidadeModule, store);
    estoqueStore = getModule(EstoqueModule, store);
    feriadoStore = getModule(FeriadoModule, store);
    filtroPorTabelaStore = getModule(FiltroPorTabelaModule, store);
    financeiroCategoriaJurosDescontoStore = getModule(FinanceiroCategoriaJurosDescontoModule, store);
    financeiroCategoriaStore = getModule(FinanceiroCategoriaModule, store);
    financeiroParcelaMovimentacaoStore = getModule(FinanceiroParcelaMovimentacaoModule, store);
    financeiroParcelaRenegociacaoStore = getModule(FinanceiroParcelaRenegociacaoModule, store);
    financeiroParcelaStore = getModule(FinanceiroParcelaModule, store);
    financeiroStore = getModule(FinanceiroModule, store);
    financeiroStore = getModule(FinanceiroModule, store);
    fornecedorStore = getModule(FornecedorModule, store);
    jornadaColaboradorStore = getModule(JornadaColaboradorModule, store);
    livroStore = getModule(LivroModule, store);
    logStore = getModule(LogModule, store);
    mainStore = getModule(MainModule, store);
    mapaHorarioStore = getModule(MapaHorarioModule, store);
    objecaoStore = getModule(ObjecaoModule, store);
    ocorrenciaStore = getModule(OcorrenciaModule, store);
    ocorrenciaTarefaStore = getModule(OcorrenciaTarefaModule, store);
    parceriaAcaoStore = getModule(ParceriaAcaoModule, store);
    parceriaCupomHistoricoStore = getModule(ParceriaCupomHistoricoModule, store);
    parceriaCupomStore = getModule(ParceriaCupomModule, store);
    parceriaStore = getModule(ParceriaModule, store);
    pixStore = getModule(PixModule, store);
    plugnotasConfiguracaoStore = getModule(PlugnotasConfiguracaoModule, store);
    plugnotasNfceProdutoStore = getModule(PlugnotasNfceProdutoModule, store);
    plugnotasNfeStore = getModule(PlugnotasNfeModule, store);
    plugnotasNfseServicoStore = getModule(PlugnotasNfseServicoModule, store);
    plugnotasStore = getModule(PlugnotasModule, store);
    pontoParadaStore = getModule(PontoParadaModule, store);
    prospexStore = getModule(ProspexModule, store);
    prospexDemonstrativaStore = getModule(ProspexDemonstrativaModule, store);
    quadrocdaStore = getModule(QuadroCdaModule, store);
    relatorioStore = getModule(RelatorioModule, store);
    reposicaoStore = getModule(ReposicaoModule, store);
    rotaPontoParadaAlunoStore = getModule(RotaPontoParadaAlunoModule, store);
    rotaPontoParadaStore = getModule(RotaPontoParadaModule, store);
    rotaStore = getModule(RotaModule, store);
    salaStore = getModule(SalaModule, store);
    sefazStore = getModule(SefazModule, store);
    songStore = getModule(SongModule, store);
    syngooConfiguracaoStore = getModule(SyngooConfiguracaoModule, store);
    syngooMensagemConfiguracaoStore = getModule(SyngooMensagemConfiguracaoModule, store);
    ticketStore = getModule(TicketModule, store);
    toolStore = getModule(ToolModule, store);
    treinamentoStore = getModule(TreinamentoModule, store);
    turmaAlunoAtividadeStore = getModule(TurmaAlunoAtividadeModule, store);
    turmaAlunoStore = getModule(TurmaAlunoModule, store);
    turmaAtividadeStore = getModule(TurmaAtividadeModule, store);
    turmaStore = getModule(TurmaModule, store);
    userActionStore = getModule(UserActionModule, store);
    userStore = getModule(UserModule, store);
    userToolStore = getModule(UserToolModule, store);
    utilsStore = getModule(UtilsModule, store);
    veiculoStore = getModule(VeiculoModule, store);
    helpdeskStore = getModule(HelpdeskModule, store);
    marketingDigitalStore = getModule(MarketingDigitalModule, store);
    metaStore = getModule(MetaModule, store);
    metaIndividualStore = getModule(MetaIndividualModule, store);
    novidadesStore = getModule(NovidadesModule, store);
}
export const modules = {
    action: ActionModule,
    asaas: AsaasModule,
    asaasConfiguracao: AsaasConfiguracaoModule,
    aluno: AlunoModule,
    atHome: AtHomeModule,
    analytics: AnalyticsModule,
    atividade: AtividadeModule,
    banco: BancoModule,
    bry: BryModule,
    drivechat: DrivechatModule,
    caixa: CaixaModule,
    campanha: CampanhaModule,
    cargo: CargoModule,
    cda: CdaModule,
    city: CityModule,
    company: CompanyModule,
    companyConfiguracao: CompanyConfiguracaoModule,
    companyConfiguracaoCursoDesconto: CompanyConfiguracaoCursoDescontoModule,
    companyConfiguracaoDiaVencimentoParcela: CompanyConfiguracaoDiaVencimentoParcelaModule,
    companyConfiguracaoIntegracaoBancaria: CompanyConfiguracaoIntegracaoBancariaModule,
    companyConfiguracaoMaterialParcelamento: CompanyConfiguracaoMaterialParcelamentoModule,
    companyConfiguracaoNegociacao: CompanyConfiguracaoNegociacaoModule,
    companyConfiguracaoNegociacaoMultaRescisoriaContrato: CompanyConfiguracaoNegociacaoMultaRescisoriaContratoModule,
    competicao: CompeticaoModule,
    competicaoEtapa: CompeticaoEtapaModule,
    contaBancaria: ContaBancariaModule,
    contaBancariaFormaPagamento: ContaBancariaFormaPagamentoModule,
    contrato: ContratoModule,
    curso: CursoModule,
    dashboard: DashboardModule,
    departamento: DepartamentoModule,
    diagnostico: DiagnosticoModule,
    documentoFiscal: DocumentoFiscalModule,
    endereco: EnderecoModule,
    entidade: EntidadeModule,
    estoque: EstoqueModule,
    feriado: FeriadoModule,
    filtroPorTabela: FiltroPorTabelaModule,
    financeiro: FinanceiroModule,
    financeiroCategoria: FinanceiroCategoriaModule,
    financeiroCategoriaJurosDesconto: FinanceiroCategoriaJurosDescontoModule,
    financeiroParcela: FinanceiroParcelaModule,
    financeiroParcelaMovimentacao: FinanceiroParcelaMovimentacaoModule,
    financeiroParcelaRenegociacao: FinanceiroParcelaRenegociacaoModule,
    fornecedor: FornecedorModule,
    imprimir: UtilsModule,
    jornadaColaborador: JornadaColaboradorModule,
    livro: LivroModule,
    log: LogModule,
    main: MainModule,
    mapaHorario: MapaHorarioModule,
    objecao: ObjecaoModule,
    ocorrencia: OcorrenciaModule,
    ocorrenciaTarefa: OcorrenciaTarefaModule,
    parceria: ParceriaModule,
    parceriaAcao: ParceriaAcaoModule,
    parceriaCupom: ParceriaCupomModule,
    parceriaCupomHistorico: ParceriaCupomHistoricoModule,
    pix: PixModule,
    plugnotas: PlugnotasModule,
    plugnotasConfiguracao: PlugnotasConfiguracaoModule,
    plugnotasNfceProduto: PlugnotasNfceProdutoModule,
    plugnotasNfe: PlugnotasNfeModule,
    plugnotasNfseServico: PlugnotasNfseServicoModule,
    pontoParada: PontoParadaModule,
    prospex: ProspexModule,
    prospexDemonstrativa: ProspexDemonstrativaModule,
    quadroCda: QuadroCdaModule,
    relatorio: RelatorioModule,
    reposicao: ReposicaoModule,
    rota: RotaModule,
    rotaPontoParada: RotaPontoParadaModule,
    rotaPontoParadaAluno: RotaPontoParadaAlunoModule,
    sala: SalaModule,
    sefaz: SefazModule,
    song: SongModule,
    syngooConfiguracao: SyngooConfiguracaoModule,
    syngooMensagemConfiguracao: SyngooMensagemConfiguracaoModule,
    ticket: TicketModule,
    tool: ToolModule,
    treinamento: TreinamentoModule,
    turma: TurmaModule,
    turmaAluno: TurmaAlunoModule,
    turmaAlunoAtividade: TurmaAlunoAtividadeModule,
    turmaAtividade: TurmaAtividadeModule,
    user: UserModule,
    userAction: UserActionModule,
    userTool: UserToolModule,
    veiculo: VeiculoModule,
    helpdesk: HelpdeskModule,
    marketingDigital: MarketingDigitalModule,
    meta: MetaModule,
    metaIndividual: MetaIndividualModule,
    novidades: NovidadesModule
};
export { initializeStores, actionStore, asaasStore, asaasConfiguracaoStore, alunoStore, atHomeStore, analyticsStore, atividadeStore, bancoStore, bryStore, drivechatStore, caixaStore, campanhaStore, cargoStore, cdaStore, cityStore, companyConfiguracaoCursoDescontoStore, companyConfiguracaoDiaVencimentoParcelaStore, companyConfiguracaoIntegracaoBancariaStore, companyConfiguracaoMaterialParcelamentoStore, companyConfiguracaoNegociacaoMultaRescisoriaContratoStore, companyConfiguracaoNegociacaoStore, companyConfiguracaoStore, companyStore, competicaoEtapaStore, competicaoStore, contaBancariaFormaPagamentoStore, contaBancariaStore, contratoStore, cursoStore, dashboardStore, departamentoStore, diagnosticoStore, documentoFiscalStore, enderecoStore, entidadeStore, estoqueStore, feriadoStore, filtroPorTabelaStore, financeiroCategoriaJurosDescontoStore, financeiroCategoriaStore, financeiroParcelaMovimentacaoStore, financeiroParcelaRenegociacaoStore, financeiroParcelaStore, financeiroStore, fornecedorStore, jornadaColaboradorStore, livroStore, logStore, mainStore, mapaHorarioStore, objecaoStore, ocorrenciaStore, ocorrenciaTarefaStore, parceriaAcaoStore, parceriaCupomHistoricoStore, parceriaCupomStore, parceriaStore, pixStore, plugnotasConfiguracaoStore, plugnotasNfceProdutoStore, plugnotasNfeStore, plugnotasNfseServicoStore, plugnotasStore, pontoParadaStore, prospexStore, prospexDemonstrativaStore, quadrocdaStore, relatorioStore, reposicaoStore, rotaPontoParadaAlunoStore, rotaPontoParadaStore, rotaStore, salaStore, sefazStore, songStore, syngooConfiguracaoStore, syngooMensagemConfiguracaoStore, ticketStore, toolStore, treinamentoStore, turmaAlunoAtividadeStore, turmaAlunoStore, turmaAtividadeStore, turmaStore, userActionStore, userStore, userToolStore, utilsStore, veiculoStore, helpdeskStore, marketingDigitalStore, metaStore, metaIndividualStore, novidadesStore, };
