import Vue from 'vue';
import Router from 'vue-router';
import RouterComponent from './components/RouterComponent.vue';
Vue.use(Router);
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: 'start' */ './views/main/Start.vue'),
            children: [
                {
                    path: 'login',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: 'login' */ './views/Login.vue'),
                },
                {
                    path: 'portal',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: 'login' */ './views/Login.vue'),
                },
                {
                    path: 'recover-password',
                    component: () => import('./views/PasswordRecovery.vue'),
                },
                {
                    path: 'reset-password',
                    component: () => import('./views/ResetPassword.vue'),
                },
                {
                    path: 'divulgacao/:id',
                    component: () => import('./views/divulgacao/Divulgacao.vue'),
                },
                {
                    path: 'main',
                    component: () => import('./views/main/Main.vue'),
                    children: [
                        {
                            path: 'dashboard/:elementId?',
                            name: 'main-dashboard',
                            component: () => import('./views/main/Dashboard.vue'),
                        },
                        {
                            path: 'profile',
                            component: RouterComponent,
                            redirect: 'profile/view',
                            children: [
                                {
                                    path: 'view/:elementId?',
                                    name: 'main-profile-view',
                                    component: () => import('./views/main/profile/UserProfile.vue'),
                                },
                                {
                                    path: 'edit/:elementId?',
                                    name: 'main-profile-edit',
                                    component: () => import('./views/main/profile/UserProfileEdit.vue'),
                                },
                                {
                                    path: 'password/:elementId?',
                                    name: 'main-profile-password',
                                    component: () => import('./views/main/profile/UserProfileEditPassword.vue'),
                                },
                            ],
                        },
                        {
                            path: 'admin',
                            component: () => import('./views/main/admin/Admin.vue'),
                            redirect: 'admin/users/all',
                            children: [
                                {
                                    path: 'users',
                                    redirect: 'users/all',
                                },
                                {
                                    path: 'users/all/:elementId?',
                                    name: 'main-admin-users',
                                    component: () => import('./views/main/admin/AdminUsers.vue'),
                                },
                                {
                                    path: 'users/edit/:id/:elementId?',
                                    name: 'main-admin-users-edit',
                                    component: () => import('./views/main/admin/EditUser.vue'),
                                },
                                {
                                    path: 'users/create/:elementId?',
                                    name: 'main-admin-users-create',
                                    component: () => import('./views/main/admin/CreateUser.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PedagogicoControlereposicao',
                            component: RouterComponent,
                            redirect: 'PedagogicoControlereposicao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-reposicoes',
                                    component: () => import('./views/main/reposicoes/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-reposicoes-create',
                                    component: () => import('./views/main/reposicoes/Create.vue'),
                                },
                                {
                                    path: 'reposicoes/edit/:id/:id_turma?',
                                    name: 'main-reposicoes-edit',
                                    component: () => import('./views/main/reposicoes/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PedagogicoControleaulaextra',
                            component: RouterComponent,
                            redirect: 'PedagogicoControleaulaextra/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-reforcos',
                                    component: () => import('./views/main/reforcos/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-reforcos-create',
                                    component: () => import('./views/main/reforcos/Create.vue'),
                                },
                                {
                                    path: 'reforcos/edit/:id/:elementId?',
                                    name: 'main-reforcos-edit',
                                    component: () => import('./views/main/reforcos/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Unidade',
                            component: RouterComponent,
                            redirect: 'unidade/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-unidades',
                                    component: () => import('./views/main/unidade/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-unidades-create',
                                    component: () => import('./views/main/unidade/Create.vue'),
                                },
                                {
                                    path: 'unidades/edit/:id/:elementId?',
                                    name: 'main-unidades-edit',
                                    component: () => import('./views/main/unidade/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Permissao',
                            component: RouterComponent,
                            redirect: 'permissao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-permissoes',
                                    component: () => import('./views/main/permissao/Configuracoes.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Competicoes',
                            component: RouterComponent,
                            redirect: 'competicoes/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-competicoes',
                                    component: () => import('./views/main/competicao/List.vue'),
                                },
                                {
                                    path: 'edit/:id/:elementId?',
                                    name: 'main-competicao-edit',
                                    component: () => import('./views/main/competicao/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalMuraldeaniversariantes',
                            component: RouterComponent,
                            redirect: 'OperacionalMuraldeaniversariantes/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'operacional-aniversariantes',
                                    component: () => import('./views/main/aniversariantes/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Gestaodecampanhas',
                            component: RouterComponent,
                            redirect: 'Gestaodecampanhas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-gestao-de-campanhas',
                                    component: () => import('./views/main/gestaoDeCampanhas/List.vue'),
                                },
                                {
                                    path: 'campanhas/edit/:id/:elementId?',
                                    name: 'main-gestao-de-campanhas-edit',
                                    component: () => import('./views/main/gestaoDeCampanhas/Create.vue'),
                                },
                                {
                                    path: 'campanhas/create',
                                    name: 'main-gestao-de-campanhas-create',
                                    component: () => import('./views/main/gestaoDeCampanhas/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialCampanhas',
                            component: RouterComponent,
                            redirect: 'ComercialCampanhas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-campanhas',
                                    component: () => import('./views/main/comercialCampanhas/List.vue'),
                                },
                                {
                                    path: 'parceria/edit/:id/:elementId?',
                                    name: 'main-campanhas-edit',
                                    component: () => import('./views/main/comercialCampanhas/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Comercial/',
                            component: RouterComponent,
                            redirect: 'Comercial/all',
                            children: [
                                {
                                    path: 'all',
                                    component: () => import('./views/main/comercial/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Aluno',
                            component: RouterComponent,
                            redirect: 'aluno/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-alunos',
                                    component: () => import('./views/main/aluno/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-alunos-create',
                                    component: () => import('./views/main/aluno/Create.vue'),
                                },
                                {
                                    path: 'aluno/edit/:id/:elementId?',
                                    name: 'main-alunos-edit',
                                    component: () => import('./views/main/aluno/Create.vue'),
                                },
                                {
                                    path: 'aluno/edit/:prospex_id/:elementId?',
                                    name: 'main-alunos-matricula',
                                    component: () => import('./views/main/aluno/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Checkup',
                            component: RouterComponent,
                            redirect: 'checkup/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-diagnostico',
                                    component: () => import('./views/main/diagnostico/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PedagogicoTurma',
                            component: RouterComponent,
                            redirect: 'PedagogicoTurma/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-turmas',
                                    component: () => import('./views/main/turma/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-turmas-create',
                                    component: () => import('./views/main/turma/Create.vue'),
                                },
                                // {
                                //   path: 'gestao/:elementId?',
                                //   name: 'main-turmas-gestao',
                                //   component: () => import(
                                //     /* webpackChunkName: 'main-admin-users-gestao' */ './views/main/turma/Gestao.vue'),
                                // },
                                {
                                    path: 'turma/edit/:id/:elementId?',
                                    name: 'main-turmas-edit',
                                    component: () => import('./views/main/turma/Create.vue'),
                                },
                                {
                                    path: 'aulaonline/:elementId?',
                                    name: 'main-turmas-athome',
                                    component: () => import('./views/main/turma/AtHome.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialParcerias',
                            component: RouterComponent,
                            redirect: 'ComercialParcerias/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-parcerias',
                                    component: () => import('./views/main/parcerias/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-parcerias-create',
                                    component: () => import('./views/main/parcerias/Create.vue'),
                                },
                                {
                                    path: 'parceria/edit/:id/:elementId?',
                                    name: 'main-parcerias-edit',
                                    component: () => import('./views/main/parcerias/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialCupons',
                            component: RouterComponent,
                            redirect: 'ComercialCupons/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-cupons', component: () => import('./views/main/cupons/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-cupons-create', component: () => import('./views/main/cupons/Create.vue'),
                                },
                                {
                                    path: 'cupom/edit/:id/:elementId?',
                                    name: 'main-cupons-edit', component: () => import('./views/main/cupons/Edit.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialCDA',
                            component: RouterComponent,
                            redirect: 'ComercialCDA/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-cda',
                                    component: () => import('./views/main/cda/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialCadernodeagendados',
                            component: RouterComponent,
                            redirect: 'ComercialCadernodeagendados/agendados',
                            children: [
                                {
                                    path: 'agendados/:elementId?',
                                    name: 'main-caderno-agendado',
                                    component: () => import('./views/main/cadernos/Agendado.vue'),
                                },
                            ]
                        },
                        {
                            path: 'ComercialCadernodematriculas',
                            component: RouterComponent,
                            redirect: 'ComercialCadernodematriculas/matricula',
                            children: [
                                {
                                    path: 'matricula/:elementId?',
                                    name: 'main-caderno-matricula',
                                    component: () => import('./views/main/cadernos/Matricula.vue'),
                                },
                            ]
                        },
                        {
                            path: 'ComercialCadernodecupom',
                            component: RouterComponent,
                            redirect: 'ComercialCadernodecupom/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-caderno-cupom',
                                    component: () => import('./views/main/cadernos/cupom.vue'),
                                },
                            ]
                        },
                        {
                            path: 'ComercialQuadrodeexterno',
                            component: RouterComponent,
                            redirect: 'ComercialQuadrodeexterno/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-quadro-planejamento-comercial',
                                    component: () => import('./views/main/quadros/PlanejamentoComercial.vue'),
                                },
                            ]
                        },
                        {
                            path: 'FinanceiroConferenciadecaixa',
                            component: RouterComponent,
                            redirect: 'FinanceiroConferenciadecaixa/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-caixas',
                                    component: () => import('./views/main/caixa/List.vue'),
                                },
                                {
                                    path: 'show/:id/:elementId?',
                                    name: 'main-caixas-edit',
                                    component: () => import('./views/main/caixa/Show.vue'),
                                },
                            ],
                        },
                        {
                            path: 'FinanceiroContasapagar',
                            component: RouterComponent,
                            redirect: 'FinanceiroContasapagar/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-contasapagar',
                                    props: { financeiroType: true },
                                    component: () => import('./views/main/financeiroContas/Pagar.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-contasapagar-create',
                                    props: { financeiroType: true },
                                    component: () => import('./views/main/financeiroContas/Create.vue'),
                                },
                                {
                                    path: 'contaPagar/edit/:id/:elementId?',
                                    props: { financeiroType: true },
                                    name: 'main-contasapagar-edit',
                                    component: () => import('./views/main/financeiroContas/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'FinanceiroRecebimentosporvencimento',
                            component: RouterComponent,
                            redirect: 'FinanceiroRecebimentosporvencimento/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-contasareceber',
                                    props: { financeiroType: false },
                                    component: () => import('./views/main/financeiroContas/Receber.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-contasareceber-create',
                                    props: { financeiroType: false },
                                    component: () => import('./views/main/financeiroContas/Create.vue'),
                                },
                                {
                                    path: 'contaReceber/edit/:id/:elementId?',
                                    props: { financeiroType: false },
                                    name: 'main-contasareceber-edit',
                                    component: () => import('./views/main/financeiroContas/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'FinanceiroRecebimentosporpagamento',
                            component: RouterComponent,
                            redirect: 'FinanceiroRecebimentosporpagamento/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-recebimentodecontas',
                                    props: { financeiroType: false },
                                    component: () => import('./views/main/financeiroContas/Recebimentos.vue'),
                                },
                            ],
                        },
                        {
                            path: 'FinanceiroConciliacaobancaria',
                            component: RouterComponent,
                            redirect: 'FinanceiroConciliacaobancaria/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'conciliacao-bancaria',
                                    component: () => import('./views/main/financeiro/ConciliacaoBancaria.vue'),
                                },
                            ],
                        },
                        {
                            path: 'FinanceiroContasbancarias',
                            component: RouterComponent,
                            redirect: 'FinanceiroContasbancarias/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-contas-bancarias',
                                    component: () => import('./views/main/contaBancaria/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-contas-bancarias-create',
                                    component: () => import('./views/main/contaBancaria/Create.vue'),
                                },
                                {
                                    path: 'contaBancaria/edit/:id/:elementId?',
                                    name: 'main-contas-bancarias-edit',
                                    component: () => import('./views/main/contaBancaria/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'DocumentosFiscais',
                            component: RouterComponent,
                            redirect: 'documentosfiscais/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-documentosfiscais',
                                    component: () => import('./views/main/documentoFiscal/List.vue'),
                                },
                                {
                                    path: 'documentosfiscais/show/:id/:elementId?',
                                    name: 'main-documentosfiscais-show',
                                    component: () => import('./views/main/documentoFiscal/Show.vue'),
                                },
                                {
                                    path: 'xml/:elementId?',
                                    name: 'main-documentosfiscais-xml',
                                    component: () => import('./views/main/documentoFiscal/ImportarXml.vue'),
                                },
                                {
                                    path: 'automaticamente/:elementId?',
                                    name: 'main-documentosfiscais-automaticamente',
                                    component: () => import('./views/main/documentoFiscal/ImportarAutomaticamente.vue'),
                                },
                            ],
                        },
                        {
                            path: 'CadernosCadernodeexterno',
                            component: RouterComponent,
                            redirect: 'CadernosCadernodeexterno/externo',
                            children: [
                                {
                                    path: 'externo/:elementId?',
                                    name: 'main-caderno-externo',
                                    component: () => import('./views/main/cadernos/Externo.vue'),
                                },
                            ]
                        },
                        {
                            path: 'Meufinanceiro',
                            component: RouterComponent,
                            redirect: 'MeuFinanceiro/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-meu-financeiro',
                                    component: () => import('./views/main/meuFinanceiro/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Livros',
                            component: RouterComponent,
                            redirect: 'Livros/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-livros',
                                    component: () => import('./views/main/livros/List.vue'),
                                },
                                {
                                    path: 'show/:id?',
                                    name: 'main-livros-show',
                                    component: () => import('./views/main/livros/Show.vue'),
                                },
                                {
                                    path: 'glossary/:codigo?',
                                    name: 'main-livros-glossary',
                                    component: () => import('./views/main/livros/Glossary.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Suporte',
                            component: RouterComponent,
                            redirect: 'suporte/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-suporte-list',
                                    component: () => import('./views/main/suporte/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'Gestaodeocorrencias',
                            component: RouterComponent,
                            redirect: 'gestaodeocorrencias/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-gestao-list',
                                    component: () => import('./views/main/gestaoDeOcorrencias/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'Analytics',
                            component: RouterComponent,
                            redirect: 'Analytics/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-analytics-list',
                                    component: () => import('./views/main/analytics/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'Gestaodepedidos',
                            component: RouterComponent,
                            redirect: 'gestaodepedidos/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-de-pedidos',
                                    component: () => import('./views/main/gestaoDePedidos/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'NotaFiscalNFSe',
                            component: RouterComponent,
                            redirect: 'NotaFiscalNFSe/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'nota-fiscal-nfse',
                                    component: () => import('./views/main/notaFiscal/Nfse.vue'),
                                },
                            ],
                        },
                        {
                            path: 'NotaFiscalNFCe',
                            component: RouterComponent,
                            redirect: 'NotaFiscalNFCe/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'nota-fiscal-nfce',
                                    component: () => import('./views/main/notaFiscal/Nfce.vue'),
                                },
                            ],
                        },
                        {
                            path: 'NotaFiscalNFe',
                            component: RouterComponent,
                            redirect: 'NotaFiscalNFe/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'nota-fiscal-nfe',
                                    component: () => import('./views/main/notaFiscal/Nfe.vue'),
                                },
                            ],
                        },
                        {
                            path: 'NotaFiscalConfiguracao',
                            component: RouterComponent,
                            redirect: 'NotaFiscalConfiguracao/configuracao',
                            children: [
                                {
                                    path: 'configuracao/:elementId?',
                                    name: 'main-nota-fiscal-configuracao',
                                    component: () => import('./views/main/notaFiscal/Configuracao.vue'),
                                },
                            ]
                        },
                        {
                            path: 'NotaFiscalVisaoGeral',
                            component: RouterComponent,
                            redirect: 'NotaFiscalVisaoGeral/visaoGeral',
                            children: [
                                {
                                    path: 'visaoGeral/:elementId?',
                                    name: 'main-nota-fiscal-visao-geral',
                                    component: () => import('./views/main/notaFiscal/VisaoGeral.vue'),
                                },
                            ]
                        },
                        {
                            path: 'NotaFiscalMenuFiscal',
                            component: RouterComponent,
                            redirect: 'NotaFiscalMenuFiscal/menuFiscal',
                            children: [
                                {
                                    path: 'menuFiscal/:elementId?',
                                    name: 'main-nota-fiscal-menu-fiscal',
                                    component: () => import('./views/main/notaFiscal/Fiscal.vue'),
                                },
                            ]
                        },
                        {
                            path: 'SyngooConfiguracao',
                            component: RouterComponent,
                            redirect: 'SyngooConfiguracao/config',
                            children: [
                                {
                                    path: 'config/:elementId?',
                                    name: 'syngoo-configuracao',
                                    component: () => import('./views/main/syngoo/Configuracao.vue'),
                                },
                            ],
                        },
                        {
                            path: 'EnvioDeMensagemEnviodemensagem',
                            component: RouterComponent,
                            redirect: 'EnvioDeMensagemEnviodemensagem/envio-de-mensagem',
                            children: [
                                {
                                    path: 'envio-de-mensagem/:elementId?',
                                    name: 'envio-de-mensagem-envio-de-mensagem',
                                    component: () => import('./views/main/envioDeMensagem/EnvioDeMensagem.vue'),
                                },
                            ],
                        },
                        {
                            path: 'EnvioDeMensagemHistoricodeenvio',
                            component: RouterComponent,
                            redirect: 'EnvioDeMensagemHistoricodeenvio/historico-de-envio',
                            children: [
                                {
                                    path: 'historico-de-envio/:elementId?',
                                    name: 'envio-de-mensagem-historico-de-envio',
                                    component: () => import('./views/main/envioDeMensagem/HistoricoDeEnvio.vue'),
                                },
                            ],
                        },
                        {
                            path: 'SyngooVisaoGeral',
                            component: RouterComponent,
                            redirect: 'SyngooVisaoGeral/visaoGeral',
                            children: [
                                {
                                    path: 'visaoGeral/:elementId?',
                                    name: 'main-syngoo-visao-geral',
                                    component: () => import('./views/main/syngoo/VisaoGeral.vue'),
                                },
                            ]
                        },
                        {
                            path: 'EnvioDeMensagemConfiguracaodemensagem',
                            component: RouterComponent,
                            redirect: 'EnvioDeMensagemConfiguracaodemensagem/configuracao-de-mensagem',
                            children: [
                                {
                                    path: 'configuracao-de-mensagem/:elementId?',
                                    name: 'envio-de-mensagem-configuracao-de-mensagem-geral',
                                    component: () => import('./views/main/envioDeMensagem/ConfiguracaoDeMensagem.vue'),
                                },
                            ]
                        },
                        {
                            path: 'OperacionalMeucaixa',
                            component: RouterComponent,
                            redirect: 'OperacionalMeucaixa/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'operacional-meucaixa',
                                    component: () => import('./views/main/dashboard/Caixa.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalAgendarreposicao',
                            component: RouterComponent,
                            redirect: 'OperacionalAgendarreposicao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'operacional-reposicao',
                                    component: () => import('./views/main/dashboard/reposicao/Reposicao.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalCobranca',
                            component: RouterComponent,
                            redirect: 'OperacionalCobranca/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'operacional-analisecontratos',
                                    component: () => import('./views/main/dashboard/Cobranca.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalRecepcao',
                            component: RouterComponent,
                            redirect: 'OperacionalRecepcao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'operacional-recepcao',
                                    component: () => import('./views/main/dashboard/Recepcao.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalCadernodereposicao',
                            component: RouterComponent,
                            redirect: 'OperacionalCadernodereposicao/reposicao',
                            children: [
                                {
                                    path: 'reposicao/:elementId?',
                                    name: 'main-caderno-reposicao',
                                    component: () => import('./views/main/cadernos/Reposicao.vue'),
                                },
                            ]
                        },
                        {
                            path: 'OperacionalEstoque',
                            component: RouterComponent,
                            redirect: 'OperacionalEstoque/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-estoque',
                                    component: () => import('./views/main/estoque/List.vue'),
                                },
                                {
                                    path: 'create/:livro_id?/:elementId?',
                                    name: 'main-estoque-create',
                                    component: () => import('./views/main/estoque/Create.vue'),
                                },
                                {
                                    path: 'edit/:livro_id?/:saldo?',
                                    name: 'main-estoque-edit',
                                    component: () => import('./views/main/estoque/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalProspex',
                            component: RouterComponent,
                            redirect: 'Operacionalprospex/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-prospexs',
                                    component: () => import('./views/main/prospex/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-prospexs-create',
                                    component: () => import('./views/main/prospex/Create.vue'),
                                },
                                {
                                    path: 'prospex/edit/:id/:elementId?',
                                    name: 'main-prospexs-edit',
                                    component: () => import('./views/main/prospex/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalFeriados',
                            component: RouterComponent,
                            redirect: 'OperacionalFeriados/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-feriados',
                                    component: () => import('./views/main/feriados/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-feriados-create',
                                    component: () => import('./views/main/feriados/Create.vue'),
                                },
                                {
                                    path: 'feriados/edit/:id/:elementId?',
                                    name: 'main-feriados-edit',
                                    component: () => import('./views/main/feriados/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalVeiculos',
                            component: RouterComponent,
                            redirect: 'OperacionalVeiculos/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-veiculos',
                                    component: () => import('./views/main/veiculo/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-veiculos-create',
                                    component: () => import('./views/main/veiculo/Create.vue'),
                                },
                                {
                                    path: 'veiculo/edit/:id/:elementId?',
                                    name: 'main-veiculos-edit',
                                    component: () => import('./views/main/veiculo/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalRotas',
                            component: RouterComponent,
                            redirect: 'OperacionalRotas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-rotas',
                                    component: () => import('./views/main/rota/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-rotas-create',
                                    component: () => import('./views/main/rota/Create.vue'),
                                },
                                {
                                    path: 'rota/edit/:id/:elementId?',
                                    name: 'main-rotas-edit',
                                    component: () => import('./views/main/rota/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalPontosdeParada',
                            component: RouterComponent,
                            redirect: 'OperacionalPontosdeParada/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-pontos-parada',
                                    component: () => import('./views/main/pontoParada/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-pontos-parada-create',
                                    component: () => import('./views/main/pontoParada/Create.vue'),
                                },
                                {
                                    path: 'ponto-parada/edit/:id/:elementId?',
                                    name: 'main-pontos-parada-edit',
                                    component: () => import('./views/main/pontoParada/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalAgendaraulaextra',
                            component: RouterComponent,
                            redirect: 'OperacionalAgendaraulaextra/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-reforco',
                                    component: () => import('./views/main/dashboard/Reforco.vue'),
                                },
                            ],
                        },
                        {
                            path: 'OperacionalRelatoriodefaltas',
                            component: RouterComponent,
                            redirect: 'OperacionalRelatoriodefaltas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-relatoriofaltas',
                                    component: () => import('./views/main/relatorioFaltas/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoTarefasereunioespendentes',
                            component: RouterComponent,
                            redirect: 'GestaoTarefasereunioespendentes/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-tarefas-e-reunioes-pendentes',
                                    component: () => import('./views/main/dashboard/Ocorrencia.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PedagogicoProfessor',
                            component: RouterComponent,
                            redirect: 'PedagogicoProfessor/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'pedagogico-professor',
                                    component: () => import('./views/main/dashboard/Professor.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PedagogicoQuadropedagogico',
                            component: RouterComponent,
                            redirect: 'PedagogicoQuadropedagogico/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-quadro-pedagogico',
                                    component: () => import('./views/main/quadros/Pedagogico.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoRetencao',
                            component: RouterComponent,
                            redirect: 'GestaoRetencao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-retencao',
                                    component: () => import('./views/main/dashboard/Retencao.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoEntregadelivro',
                            component: RouterComponent,
                            redirect: 'GestaoEntregadelivro/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-entrega-livro',
                                    component: () => import('./views/main/dashboard/EntregaLivro.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoAnaliseContratos',
                            component: RouterComponent,
                            redirect: 'GestaoAnaliseContratos/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-analisecontratos',
                                    component: () => import('./views/main/dashboard/Contrato.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoCheckup',
                            component: RouterComponent,
                            redirect: 'checkup/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-diagnostico',
                                    component: () => import('./views/main/diagnostico/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoGestaodeocorrencias',
                            component: RouterComponent,
                            redirect: 'gestaodeocorrencias/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-gestao-list',
                                    component: () => import('./views/main/gestaoDeOcorrencias/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoGestaodepedidos',
                            component: RouterComponent,
                            redirect: 'gestaodepedidos/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-gestao-list',
                                    component: () => import('./views/main/gestaoDePedidos/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoColaboradores',
                            component: RouterComponent,
                            redirect: 'GestaoColaboradores/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-colaboradores',
                                    component: () => import('./views/main/colaboradores/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-colaboradores-create',
                                    component: () => import('./views/main/colaboradores/Create.vue'),
                                },
                                {
                                    path: 'colaboradores/edit/:id/:elementId?',
                                    name: 'main-colaboradores-edit',
                                    component: () => import('./views/main/colaboradores/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoConfiguracoes',
                            component: RouterComponent,
                            redirect: 'GestaoConfiguracoes/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-configuracoes',
                                    component: () => import('./views/main/companyConfiguracoes/List.vue'),
                                },
                                {
                                    path: 'contrato/:elementId?/:alunoId?',
                                    name: 'main-configuracoes-contrato',
                                    component: () => import('./views/main/companyConfiguracoes/Contrato.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoPagamentododrive',
                            component: RouterComponent,
                            redirect: 'GestaoPagamentododrive/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-financeiro-list',
                                    component: () => import('./views/main/financeiro/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoMapadehorarios',
                            component: RouterComponent,
                            redirect: 'GestaoMapadehorarios/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-mapadehorarios',
                                    component: () => import('./views/main/mapaHorarios/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'GestaoSala',
                            component: RouterComponent,
                            redirect: 'GestaoSala/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-salas',
                                    component: () => import('./views/main/sala/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-salas-create',
                                    component: () => import('./views/main/sala/Create.vue'),
                                },
                                {
                                    path: 'sala/edit/:id/:elementId?',
                                    name: 'main-salas-edit',
                                    component: () => import('./views/main/sala/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoFornecedores',
                            component: RouterComponent,
                            redirect: 'GestaoFornecedores/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-fornecedores',
                                    component: () => import('./views/main/fornecedores/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-fornecedores-create',
                                    component: () => import('./views/main/fornecedores/Create.vue'),
                                },
                                {
                                    path: 'fornecedores/edit/:id/:elementId?',
                                    name: 'main-fornecedores-edit',
                                    component: () => import('./views/main/fornecedores/Create.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoLogsdoSistema',
                            component: RouterComponent,
                            redirect: 'GestaoLogsdoSistema/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'logs-sistema',
                                    component: () => import('./views/main/logsSistema/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Relatorio',
                            component: RouterComponent,
                            redirect: 'relatorio/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'relatorio',
                                    component: () => import('./views/main/relatorio/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Treinamentos',
                            component: RouterComponent,
                            redirect: 'treinamentos/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-treinamentos',
                                    component: () => import('./views/main/treinamentos/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-treinamentos-create',
                                    component: () => import('./views/main/treinamentos/Create.vue'),
                                },
                                {
                                    path: 'treinamento/edit/:id/:elementId?',
                                    name: 'main-treinamentos-edit',
                                    component: () => import('./views/main/treinamentos/Create.vue'),
                                },
                            ],
                        },
                        // at home
                        {
                            path: 'KnnAtHome/',
                            component: RouterComponent,
                            redirect: 'KnnAtHome/all',
                            children: [
                                {
                                    path: 'all',
                                    component: () => import('./views/main/atHome/List.vue'),
                                },
                                {
                                    path: 'details/:id',
                                    name: 'at-home-details',
                                    component: () => import('./views/main/atHome/Details.vue'),
                                },
                            ],
                        },
                        {
                            path: 'PhenomConnect/',
                            component: RouterComponent,
                            redirect: 'PhenomConnect/all',
                            children: [
                                {
                                    path: 'all',
                                    component: () => import('./views/main/atHome/List.vue'),
                                },
                                {
                                    path: 'details/:id',
                                    name: 'at-home-details',
                                    component: () => import('./views/main/atHome/Details.vue'),
                                },
                            ],
                        },
                        {
                            path: 'Pedagogico',
                            component: RouterComponent,
                            redirect: 'Pedagogico/all',
                            children: [
                                {
                                    path: 'all',
                                    component: () => import('./views/main/pedagogico/List.vue'),
                                },
                                {
                                    path: 'notas/:id',
                                    name: 'pedagogico-notas',
                                    component: () => import('./views/main/pedagogico/Notas.vue'),
                                },
                                {
                                    path: 'minha-pratica/:id',
                                    name: 'pedagogico-minha-pratica',
                                    component: () => import('./views/main/pedagogico/MinhaPratica.vue'),
                                },
                            ],
                        },
                        {
                            path: 'DataDriven',
                            component: RouterComponent,
                            redirect: 'dataDriven/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-data-driven',
                                    component: () => import('./views/main/dataDriven/List.vue'),
                                }
                            ]
                        },
                        {
                            path: 'Helpdesk',
                            component: RouterComponent,
                            redirect: 'helpdesk/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-helpdesk',
                                    component: () => import('./views/main/helpdesk/List.vue'),
                                },
                                {
                                    path: 'create/:elementId?',
                                    name: 'main-helpdesk-create',
                                    component: () => import('./views/main/helpdesk/Create.vue'),
                                },
                                {
                                    path: 'helpdesk/edit/:id/:elementId?',
                                    name: 'main-helpdesk-edit',
                                    component: () => import('./views/main/helpdesk/Show.vue'),
                                }
                            ]
                        },
                        {
                            path: 'DriveChat',
                            component: RouterComponent,
                            redirect: 'drivechat/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-drivechat',
                                    component: () => import('./views/main/drivechat/List.vue'),
                                },
                            ]
                        },
                        {
                            path: 'Certificados',
                            component: RouterComponent,
                            redirect: 'certificados/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'certificados',
                                    component: () => import('./views/main/certificados/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'DrivingMarketing',
                            component: RouterComponent,
                            redirect: 'DrivingMarketing/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'driving-marketing',
                                    component: () => import('./views/main/DrivingMarketing/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'GestaoMktdigitalnacional',
                            component: RouterComponent,
                            redirect: 'GestaoMktdigitalnacional/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'gestao-mkt-digital-nacional',
                                    component: () => import('./views/main/GestaoMktdigitalnacional/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'ComercialMetas',
                            component: RouterComponent,
                            redirect: 'ComercialMetas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'metas',
                                    component: () => import('./views/main/comercialMetas/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'DriverMetas',
                            component: RouterComponent,
                            redirect: 'DriverMetas/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'driver-metas',
                                    component: () => import('./views/main/driverMetas/List.vue'),
                                },
                                {
                                    path: 'comercial/:elementId?',
                                    name: 'driver-metas-comercial',
                                    component: () => import('./views/main/driverMetas/Comercial.vue'),
                                },
                            ],
                        },
                        {
                            path: 'RetencaoEntregaDeBoletim',
                            component: RouterComponent,
                            redirect: 'RetencaoEntregaDeBoletim/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'main-entregadeboletim',
                                    component: () => import('./views/main/entregaBoletim/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'RetencaoCadernoderematriculas',
                            component: RouterComponent,
                            redirect: 'RetencaoCadernoderematriculas/rematricula',
                            children: [
                                {
                                    path: 'rematricula/:elementId?',
                                    name: 'main-caderno-rematricula',
                                    component: () => import('./views/main/cadernos/Rematricula.vue'),
                                },
                            ]
                        },
                        {
                            path: 'RetencaoCadernodedesistencia',
                            component: RouterComponent,
                            redirect: 'RetencaoCadernodedesistencia/desistencia',
                            children: [
                                {
                                    path: 'desistencia/:elementId?',
                                    name: 'main-caderno-desistencia',
                                    component: () => import('./views/main/cadernos/Desistencia.vue'),
                                },
                            ]
                        },
                        {
                            path: 'RetencaoRetencao',
                            component: RouterComponent,
                            redirect: 'RetencaoRetencao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'retencao-retencao',
                                    component: () => import('./views/main/dashboard/Retencao.vue'),
                                },
                            ],
                        },
                        {
                            path: 'RetencaoRematricula',
                            component: RouterComponent,
                            redirect: 'RetencaoRematricula/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'retencao-rematricula',
                                    component: () => import('./views/main/dashboard/Rematricula.vue'),
                                },
                            ],
                        },
                        {
                            path: 'RetencaoPedidosdeReuniao',
                            component: RouterComponent,
                            redirect: 'RetencaoPedidosdeReuniao/all',
                            children: [
                                {
                                    path: 'all/:elementId?',
                                    name: 'pedidos-reuniao',
                                    component: () => import('./views/main/pedidosReuniao/List.vue'),
                                },
                            ],
                        },
                        {
                            path: 'RetencaoCadernodeevadidos',
                            component: RouterComponent,
                            redirect: 'RetencaoCadernodeevadidos/evadidos',
                            children: [
                                {
                                    path: 'evadidos/:elementId?',
                                    name: 'main-caderno-evadidos',
                                    component: () => import('./views/main/cadernos/Evadidos.vue'),
                                },
                            ]
                        },
                    ],
                },
            ],
        },
        {
            path: '/*', redirect: '/',
        },
    ],
});
