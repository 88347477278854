import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let DashboardModule = class DashboardModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.dashboardFinanceiro = {};
        this.dashboardCobranca = {};
        this.dashboardReposicao = {};
        this.dashboardRetencao = {};
        this.dashboardPedagogico = {};
        this.dashboardCaixa = {};
        this.dashboardEntregaLivro = {};
        this.dashboardRematricula = {};
        this.dashboardContrato = {};
    }
    setDashboardFinanceiro(payload) {
        this.dashboardFinanceiro = payload;
    }
    setDashboardCobranca(payload) {
        this.dashboardCobranca = payload;
    }
    setDashboardContrato(payload) {
        this.dashboardContrato = payload;
    }
    setDashboardReposicao(payload) {
        this.dashboardReposicao = payload;
    }
    setDashboardRetencao(payload) {
        this.dashboardRetencao = payload;
    }
    setDashboardPedagogico(payload) {
        this.dashboardPedagogico = payload;
    }
    setDashboardCaixa(payload) {
        this.dashboardCaixa = payload;
    }
    setDashboardEntregaLivro(payload) {
        this.dashboardEntregaLivro = payload;
    }
    setDashboardRematricula(payload) {
        this.dashboardRematricula = payload;
    }
    async getDashboadData() {
        try {
            const response = await api.getDashboardMainPage(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardMainPageTreinamentos() {
        try {
            const response = await api.getDashboardMainPageTreinamentos(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardCobranca() {
        try {
            const response = await api.getDashboardCobranca(mainStore.token);
            if (response) {
                this.setDashboardCobranca(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardReposicao(turmaAlunoId) {
        try {
            this.setDashboardReposicao({});
            const response = await api.getDashboardReposicao(mainStore.token, turmaAlunoId);
            if (response) {
                this.setDashboardReposicao(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardRetencao() {
        try {
            const response = await api.getDashboardRetencao(mainStore.token);
            if (response) {
                this.setDashboardRetencao(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardRematricula() {
        try {
            const response = await api.getDashboardRematricula(mainStore.token);
            if (response) {
                this.setDashboardRematricula(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardPedagogico() {
        try {
            const response = await api.getDashboardPedagogico(mainStore.token);
            if (response) {
                this.setDashboardPedagogico(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardCaixa() {
        try {
            const response = await api.getDashboardCaixa(mainStore.token);
            if (response) {
                this.setDashboardCaixa(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardEntregaLivroQuery(queryIn) {
        try {
            const response = await api.getDashboardEntregaLivroQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardQuadroPedagogico(payload) {
        try {
            const response = await api.getDashboardQuadroPedagogico(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDashboardQuadroPedagogicoDetails(payload) {
        try {
            const response = await api.getDashboardQuadroPedagogicoDetails(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoPerformance(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoPerformance(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoResumo(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoResumo(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoPresencaByProfessor(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoPresencaByProfessor(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoGraficoDonut(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoGraficoDonut(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoHomeworkPorTurma(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoHomeworkPorTurma(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenPedagogicoTurmaBySemana(payload) {
        try {
            const response = await api.getDataDrivenPedagogicoTurmaBySemana(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDataDrivenFiltroOptions(payload) {
        try {
            const response = await api.getDataDrivenFiltroOptions(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardFinanceiro", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardCobranca", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardContrato", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardReposicao", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardRetencao", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardPedagogico", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardCaixa", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardEntregaLivro", null);
__decorate([
    Mutation
], DashboardModule.prototype, "setDashboardRematricula", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboadData", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardMainPageTreinamentos", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardCobranca", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardReposicao", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardRetencao", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardRematricula", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardPedagogico", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardCaixa", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardEntregaLivroQuery", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardQuadroPedagogico", null);
__decorate([
    Action
], DashboardModule.prototype, "getDashboardQuadroPedagogicoDetails", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoPerformance", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoResumo", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoPresencaByProfessor", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoGraficoDonut", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoHomeworkPorTurma", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenPedagogicoTurmaBySemana", null);
__decorate([
    Action
], DashboardModule.prototype, "getDataDrivenFiltroOptions", null);
DashboardModule = __decorate([
    Module({ name: 'dashboard' })
], DashboardModule);
export default DashboardModule;
