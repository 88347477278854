import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyModule = class CompanyModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.companies = [];
    }
    setCompanies(payload) {
        this.companies = payload;
    }
    setCompany(payload) {
        const companies = this.companies.filter((company) => company.id !== payload.id);
        companies.push(payload);
        this.companies = companies;
    }
    async createCompany(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCompany(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Unidade criada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompany(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompany(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Unidade atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyAsaasStatusId(payload) {
        await api.updateCompanyAsaasStatusId(mainStore.token, payload);
    }
    async getCompanies() {
        try {
            const response = await api.getCompanies(mainStore.token);
            if (response) {
                this.setCompanies(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompaniesFinanceiroParcelaAtraso() {
        try {
            const response = await api.getCompaniesFinanceiroParcelaAtraso(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompaniesActive() {
        try {
            const response = await api.getCompaniesActive(mainStore.token);
            if (response) {
                this.setCompanies(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompany(companyId) {
        try {
            const response = await api.getCompany(mainStore.token, companyId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyDadosUnidade(companyId) {
        try {
            const response = await api.getCompanyDadosUnidade(mainStore.token, companyId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerarContrato(id) {
        try {
            const response = await api.gerarContrato(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerarFinanceiro(payload) {
        try {
            const response = await api.gerarFinanceiro(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompany(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompany(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Unidade excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompanyData(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompanyData(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Unidade excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async unactiveCompany(id) {
        const loadingNotification = { content: 'Inativando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.unactiveCompany(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async exportCompanyData(id) {
        const loadingNotification = { content: 'Gerando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.exportCompanyData(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async companyGetAllStatus() {
        try {
            const response = await api.companyGetAllStatus(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CompanyModule.prototype, "setCompanies", null);
__decorate([
    Mutation
], CompanyModule.prototype, "setCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "createCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "updateCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "updateCompanyAsaasStatusId", null);
__decorate([
    Action
], CompanyModule.prototype, "getCompanies", null);
__decorate([
    Action
], CompanyModule.prototype, "getCompaniesFinanceiroParcelaAtraso", null);
__decorate([
    Action
], CompanyModule.prototype, "getCompaniesActive", null);
__decorate([
    Action
], CompanyModule.prototype, "getCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "getCompanyDadosUnidade", null);
__decorate([
    Action
], CompanyModule.prototype, "gerarContrato", null);
__decorate([
    Action
], CompanyModule.prototype, "gerarFinanceiro", null);
__decorate([
    Action
], CompanyModule.prototype, "deleteCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "deleteCompanyData", null);
__decorate([
    Action
], CompanyModule.prototype, "unactiveCompany", null);
__decorate([
    Action
], CompanyModule.prototype, "exportCompanyData", null);
__decorate([
    Action
], CompanyModule.prototype, "companyGetAllStatus", null);
CompanyModule = __decorate([
    Module({ name: 'company' })
], CompanyModule);
export default CompanyModule;
