import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ContratoModule = class ContratoModule extends VuexModule {
    async createContrato(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Contrato criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContratos(payload) {
        try {
            const response = await api.getContratos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratosCadernoMatriculas(payload) {
        try {
            const response = await api.getContratosCadernoMatriculas(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratosAlunosRematricular(payload) {
        try {
            const response = await api.getContratosAlunosRematricular(mainStore.token, payload.dataInicial, payload.dataFinal);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratoMovimetacao(contratoId) {
        try {
            const response = await api.getContratoMovimentacao(mainStore.token, contratoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratoLivroMovimentacao(contratoId) {
        try {
            const response = await api.getContratoLivroMovimentacao(mainStore.token, contratoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDadosCancelamento(alunoId = null) {
        try {
            const response = await api.getContratoDadosCancelamento(mainStore.token, alunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTaxaMatricula(contrato_id) {
        try {
            const response = await api.getAlunoTaxaMatricula(mainStore.token, contrato_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCadernoDesistenciaQuery(queryIn) {
        try {
            const response = await api.getCadernoDesistenciaQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratoQuery(queryIn) {
        try {
            const response = await api.getContratoQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContrato(id) {
        try {
            const response = await api.getContrato(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async imprimirReciboEntregaMaterial(id) {
        try {
            const response = await api.imprimirReciboEntregaMaterial(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async alterarEstagio(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.alterarEstagio(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async adicionarEstagio(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.adicionarEstagio(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async solicitarLivroParaFranchiseEstagioLivro(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.solicitarLivroParaFranchiseEstagioLivro(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async solicitarLivroParaFranchiseVerificaPossibilidade(contratoLivroId) {
        try {
            const response = await api.solicitarLivroParaFranchiseVerificaPossibilidade(mainStore.token, contratoLivroId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateContratoVigenciaInicio(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateContratoVigenciaInicio(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateContrato(payload) {
        try {
            const response = await api.updateContrato(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async imprimirTermoDesistencia(id) {
        try {
            const response = await api.imprimirTermoDesistencia(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async imprimirTermoTrancamento(id) {
        try {
            const response = await api.imprimirTermoTrancamento(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async rescindirContrato(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.rescindirContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500))
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Contrato rescindido com sucesso',
                color: 'success',
            });
            return '';
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteContrato(contratoId) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteContrato(mainStore.token, contratoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500))
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Contrato excluído com sucesso',
                color: 'success',
            });
            return '';
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteContratoLivro(id) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteContratoLivro(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500))
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Estágio excluído com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async trancarContrato(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.trancarContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500))
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Contrato trancado com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getMasterPreInvoice(payload) {
        const loadingNotification = { content: 'Carregando...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.getMasterPreInvoice(mainStore.token, payload.preInvoiceId, payload.contratoLivroId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Pedido carregado com sucesso',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerarFinanceiroLivro(payload) {
        const loadingNotification = { content: 'Gerando...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.gerarFinanceiroLivro(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcelas geradas com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async desvincularLivroMaster(preInvoiceId) {
        const loadingNotification = { content: 'Desvinculando...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.desvincularLivroMaster(mainStore.token, preInvoiceId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Desvinculado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async contratoAlterarTransporte(contratoTransporte) {
        try {
            await api.contratoAlterarTransporte(mainStore.token, contratoTransporte);
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async contratoRematriculou(contratoId) {
        try {
            const result = await api.contratoRematriculou(mainStore.token, contratoId);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async contratoNaoRematriculou(contratoId) {
        try {
            const result = await api.contratoNaoRematriculou(mainStore.token, contratoId);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async contratoFinalizou(contratoId) {
        try {
            const result = await api.contratoFinalizou(mainStore.token, contratoId);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCursoId(payload) {
        try {
            const response = await api.updateContratoCursoId(mainStore.token, payload.contratoId, payload.cursoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async contratoForcarEvasao(contratoId) {
        const loadingNotification = { content: 'Evadindo o aluno', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.contratoForcarEvasao(mainStore.token, contratoId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContratoByConsultorMetas(payload) {
        try {
            const response = await api.getContratoByConsultorMetas(mainStore.token, payload.consultor_id, payload.data);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response?.data?.detail || 'Erro ao buscar contratos',
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], ContratoModule.prototype, "createContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratos", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratosCadernoMatriculas", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratosAlunosRematricular", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratoMovimetacao", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratoLivroMovimentacao", null);
__decorate([
    Action
], ContratoModule.prototype, "getDadosCancelamento", null);
__decorate([
    Action
], ContratoModule.prototype, "getTaxaMatricula", null);
__decorate([
    Action
], ContratoModule.prototype, "getCadernoDesistenciaQuery", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratoQuery", null);
__decorate([
    Action
], ContratoModule.prototype, "getContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "imprimirReciboEntregaMaterial", null);
__decorate([
    Action
], ContratoModule.prototype, "alterarEstagio", null);
__decorate([
    Action
], ContratoModule.prototype, "adicionarEstagio", null);
__decorate([
    Action
], ContratoModule.prototype, "solicitarLivroParaFranchiseEstagioLivro", null);
__decorate([
    Action
], ContratoModule.prototype, "solicitarLivroParaFranchiseVerificaPossibilidade", null);
__decorate([
    Action
], ContratoModule.prototype, "updateContratoVigenciaInicio", null);
__decorate([
    Action
], ContratoModule.prototype, "updateContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "imprimirTermoDesistencia", null);
__decorate([
    Action
], ContratoModule.prototype, "imprimirTermoTrancamento", null);
__decorate([
    Action
], ContratoModule.prototype, "rescindirContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "deleteContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "deleteContratoLivro", null);
__decorate([
    Action
], ContratoModule.prototype, "trancarContrato", null);
__decorate([
    Action
], ContratoModule.prototype, "getMasterPreInvoice", null);
__decorate([
    Action
], ContratoModule.prototype, "gerarFinanceiroLivro", null);
__decorate([
    Action
], ContratoModule.prototype, "desvincularLivroMaster", null);
__decorate([
    Action
], ContratoModule.prototype, "contratoAlterarTransporte", null);
__decorate([
    Action
], ContratoModule.prototype, "contratoRematriculou", null);
__decorate([
    Action
], ContratoModule.prototype, "contratoNaoRematriculou", null);
__decorate([
    Action
], ContratoModule.prototype, "contratoFinalizou", null);
__decorate([
    Action
], ContratoModule.prototype, "updateCursoId", null);
__decorate([
    Action
], ContratoModule.prototype, "contratoForcarEvasao", null);
__decorate([
    Action
], ContratoModule.prototype, "getContratoByConsultorMetas", null);
ContratoModule = __decorate([
    Module({ name: 'contrato' })
], ContratoModule);
export default ContratoModule;
