import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let BryModule = class BryModule extends VuexModule {
    async getOverview() {
        try {
            const response = await api.drivechatGetOverview(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], BryModule.prototype, "getOverview", null);
BryModule = __decorate([
    Module({ name: 'drivechat' })
], BryModule);
export default BryModule;
