import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import { getNomeAulaExtraSingular } from '@/utils';
let ReposicaoModule = class ReposicaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.reposicoes = [];
    }
    setReposicoes(payload) {
        this.reposicoes = payload;
    }
    setReposicao(payload) {
        const reposicoes = this.reposicoes.filter((company) => company.id !== payload.id);
        reposicoes.push(payload);
        this.reposicoes = reposicoes;
    }
    async createReposicao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createReposicao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createReposicoesAlunos(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createReposicoesAlunos(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async changeAprovacaoReposicao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.changeAprovacaoReposicao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: `Reposicao ${payload.value ? 'aprovada' : 'reprovada'}.`,
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateReposicao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateReposicao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: `${getNomeAulaExtraSingular()} atualizada com sucesso`,
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateReposicaoAlunosPresenca(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateReposicaoAlunosPresenca(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Reposição atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoes() {
        try {
            const response = await api.getReposicoes(mainStore.token);
            if (response) {
                this.setReposicoes(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesByTurmaAtividade(id) {
        try {
            const response = await api.getReposicoesByTurmaAtividade(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesByAluno(alunoId) {
        try {
            const response = await api.getReposicoesByAluno(mainStore.token, alunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesAlunos(payload) {
        try {
            const response = await api.getReposicoesAlunos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getNewReposicoesAlunos() {
        try {
            const response = await api.getNewReposicoesAlunos(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicaoAlunos(reposicaoId) {
        try {
            const response = await api.getReposicaoAlunos(mainStore.token, reposicaoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicaoAlunosHojeByProfessor(professorId) {
        try {
            const response = await api.getReposicaoAlunosHojeByProfessor(mainStore.token, professorId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicaoHojeByProfessor(professorId) {
        try {
            const response = await api.getReposicaoHojeByProfessor(mainStore.token, professorId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReforcoAlunosHojeByProfessor(professorId) {
        try {
            const response = await api.getReforcoAlunosHojeByProfessor(mainStore.token, professorId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteReposicao(reposicaoId) {
        try {
            const response = await api.deleteReposicao(mainStore.token, reposicaoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReforcoHojeByProfessor(professorId) {
        try {
            const response = await api.getReforcoHojeByProfessor(mainStore.token, professorId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicao(id) {
        try {
            const response = await api.getReposicao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesReposicaoQuery(queryIn) {
        try {
            const response = await api.getReposicoesQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesAulaExtraQuery(queryIn) {
        try {
            const response = await api.getAulaExtraQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReforcoAlunosAprovados() {
        try {
            const response = await api.getReforcoAlunosAprovados(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ReposicaoModule.prototype, "setReposicoes", null);
__decorate([
    Mutation
], ReposicaoModule.prototype, "setReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "createReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "createReposicoesAlunos", null);
__decorate([
    Action
], ReposicaoModule.prototype, "changeAprovacaoReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "updateReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "updateReposicaoAlunosPresenca", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoes", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoesByTurmaAtividade", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoesByAluno", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoesAlunos", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getNewReposicoesAlunos", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicaoAlunos", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicaoAlunosHojeByProfessor", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicaoHojeByProfessor", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReforcoAlunosHojeByProfessor", null);
__decorate([
    Action
], ReposicaoModule.prototype, "deleteReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReforcoHojeByProfessor", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicao", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoesReposicaoQuery", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReposicoesAulaExtraQuery", null);
__decorate([
    Action
], ReposicaoModule.prototype, "getReforcoAlunosAprovados", null);
ReposicaoModule = __decorate([
    Module({ name: 'reposicao' })
], ReposicaoModule);
export default ReposicaoModule;
